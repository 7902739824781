import React from "react";
import Error from "../error/error";
import state, { StateParameter } from "../../utils/state";
import { Settle } from "settle_planner";
import { backendAPI } from "settle_planner/dist/esm/App";

interface IProps {}

const Planner: React.FC<IProps> = (props) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const paremeterState = queryParameters.get("s");

  if (!paremeterState) {
    return <Error errorCode={405} />;
  }

  const userState = new state();
  const isSaved = userState.save(paremeterState);
  if (!isSaved) {
    return <Error errorCode={406} />;
  }

  const customerId = userState.getParameter(StateParameter.customerPublicId);

  let backendApi = backendAPI.live;

  if (process.env.REACT_APP_BACKEND_URL) {
    if (process.env.REACT_APP_BACKEND_URL?.indexOf("stage") > -1) {
      backendApi = backendAPI.stage;
    }
    if (process.env.REACT_APP_BACKEND_URL?.indexOf("localhost") > -1) {
      backendApi = backendAPI.localhost;
    }
  }

  console.log("customerId:", customerId);
  return <Settle userId={customerId} onConcentApproved={() => {}} backendApi={backendApi} />;
};

export default Planner;
